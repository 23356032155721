/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from 'react-i18next'





const Form = () => {
  const auth = getAuth();
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('passwordReset.validEmail'))
      .required(t('passwordReset.emailRequired')),
  });

  //auth.languageCode = 'es';

  const initialValues = {
    email: '',
  };

  const onSubmit = (values) => {
    sendPasswordResetEmail(auth, formik.values.email)
    .then(() => {
      // Password reset email sent!
      // ..
      alert(t('passwordReset.passwordResetSended'))
    })
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      console.log(error.code)
      console.log(error.message)
      // ..
      // eslint-disable-next-line react-hooks/exhaustive-deps

    });
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
 
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          {t('passwordReset.recoverAccount')}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {t('passwordReset.forgotPassword')}
        </Typography>
        <Typography color="text.secondary">
        {t('passwordReset.enterEmailBelow')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            {t('passwordReset.enterEmail')}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  component={Link}
                  href={'/signin'}
                  to={'/signin'}
                  fullWidth
                >
                  {t('passwordReset.backToLogin')}
                </Button>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
              {t('passwordReset.sendResetLink')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
