import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { APIQR_Info } from '../Models/APIQRInfo'
import Spinner from '../components/Spinner';
import { APIQRINFO } from "../functions/ApiClient";

const Dashboard = () => {
  const { t } = useTranslation();
  const [collectionInfo, setCollectionInfo] = useState([])
  let [chargingSpinner, setChargingSpinner] = useState(false)
  
  useEffect(async () => {
    try {
      setChargingSpinner(true)
      let result = await APIQRINFO.getInfo();
      let newInfo = result.data;
      let jsonInfo = new APIQR_Info(newInfo);
      setCollectionInfo(jsonInfo);
      setChargingSpinner(false)
    } catch (error) {
      console.log("error charging list", error);
    }
  }, []);



  return (
    <>
      {chargingSpinner === false ? (
        <Fragment>
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-2 col-xs-2">
              <div id="panel-3" className="panel">
                <div className="panel-hdr">
                  <h2>Cantidad de cuentas de usuario</h2>
                </div>
                <div className="panel-container">
                  <div className="panel-content">
                    <div className="d-flex justify-content-center">
                      <h1 className="h1">{collectionInfo.TotalUsers}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-2 col-xs-2">
              <div id="panel-3" className="panel">
                <div className="panel-hdr">
                  <h2>Cantidad de cuentas de WhatsApp</h2>
                </div>
                <div className="panel-container">
                  <div className="panel-content">
                    <div className="d-flex justify-content-center">
                      <h1 className="h1">{collectionInfo.TotalAccounts}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-2 col-xs-2">
              <div id="panel-4" className="panel">
                <div className="panel-hdr">
                  <h2>Errores</h2>
                </div>
                <div className="panel-container">
                  <div className="panel-content">
                    <div className="d-flex justify-content-center">
                      <h1 className="h1">{collectionInfo.Errores}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-2 col-xs-2">
              <div id="panel-4" className="panel">
                <div className="panel-hdr">
                  <h2>{t("dashboard.diskSpace")}</h2>
                </div>
                <div className="panel-container">
                  <div className="panel-content">
                    <div className="d-flex justify-content-center">
                      <h1 className="h1">{collectionInfo.DiskSpace}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Spinner style={{ top: "35vh" }}></Spinner>
      )}
    </>
  );
};

export default Dashboard;
