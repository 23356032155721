class APIQR_Info {
    TotalAccounts: number;
    TotalUsers: number;
    Errores: number;
    DiskSpace: string;


    constructor(json: any) {
        if (json) {
            this.TotalAccounts = json.TotalAccounts ? json.TotalAccounts : 0
            this.TotalUsers = json.TotalUsers ? json.TotalUsers : 0
            this.Errores = json.Errores ? json.Errores : 0
            this.DiskSpace = json.DiskSpace ? json.DiskSpace : ""

        } else {
            this.TotalAccounts = 0
            this.TotalUsers = 0
            this.Errores = 0
            this.DiskSpace = ""

        }
    }
};
export { APIQR_Info }