import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

const LayoutCentered = (props) => {
  return (
    <Fragment>
    <div className="d-flex flex-grow-1 border-faded border-0">
      <div className="page-content p-0">
        <div className="d-flex justify-content-center row">
          <div className="col-lg-7">
              {props.children}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
  )
}
LayoutCentered.propTypes = {
  children: PropTypes.node,
};
export default LayoutCentered