import React, {useState,useContext, useEffect} from 'react' //eslint-disable-line
import { useTranslation } from 'react-i18next'
import BoxInfoState from '../utils/boxInfoState'
import LayoutCentered from '../layoutCentered'
import "react-toastify/dist/ReactToastify.css";
import { APICuentas, APIUserAccount } from "../../functions/ApiClient";
import Spinner from '../Spinner';
import { WaAccount } from '../../Models/WaAccount'
import { Box, Typography } from '@mui/material';

// toast.configure()

const ListWaAccounts = (props) => {
  const { t } = useTranslation();
  const [collectionAccounts, setCollectionAccounts] = useState([])
  const [collectionAccountsCantidad, setCollectionAccountsCantidad] = useState(0)
  const [userSelected, setUserSelected] = useState({})
  let [chargingSpinner, setChargingSpinner] = useState(false)

  
  //eslint-disable-next-line
  useEffect(async () => {
   try{
    setChargingSpinner(true)
    let path = window.location.pathname.split('/')
    // console.log(path)
    // console.log("id",path[2])

    // let result = await APICuentas.getCuentas();
    let result = await APIUserAccount.obtenerListaCuentasWAUsuarios(path[2]);
    let resultUserSelected = await APIUserAccount.obtenerUnUsuario(path[2]);

    const userSelectedResult = resultUserSelected.data

    let array = [];
    result.data.forEach((json) => {
      let jsonWa = new WaAccount(json);
      array.push(jsonWa);
    });

    setUserSelected(userSelected => ({
      ...userSelected,
      ...userSelectedResult
    }));
  
    setCollectionAccounts(array);
    setCollectionAccountsCantidad(array.length)
    setChargingSpinner(false)

   }catch(error){
     console.log("error charging list", error)
   }    
  }, []);

  console.log(collectionAccounts.length)
  

  return (
    <>
      <>
        <div className="content">
          <Box display="flex" flexDirection="column" alignItems={"center"}>
            <Box>
              <Box display="flex">
                <Typography sx={{ width: 80, fontWeight: 500, textAlign: 'end', mr: "20px" }}>Nombre: </Typography>
                <Typography> {userSelected.name}</Typography>
              </Box>
              <Box display="flex">
                <Typography sx={{ width: 80, fontWeight: 500, textAlign: 'end', mr: "20px" }}>Email: </Typography>
                <Typography> {userSelected.email}</Typography>
              </Box>
              <Box display="flex">
                <Typography sx={{ width: 80, fontWeight: 500, textAlign: 'end', mr: "20px" }}>ID: </Typography>
                <Typography> {userSelected.id}</Typography>
              </Box>
            </Box>
          </Box>
          <h2 className="text-center m-3 h1">Cuentas de WhatsApp ({collectionAccountsCantidad})</h2>
        </div>
        <LayoutCentered>
          {chargingSpinner === false ? (
            collectionAccounts.map((item, i) => (
              <BoxInfoState key={i} account={item} />
            ))
          ) : (
            <Spinner style={{ top: "35vh" }}></Spinner>
          )}
        </LayoutCentered>
      </>
    </>
  );
};

export default ListWaAccounts;
