import React from "react";
// import logo from "../../../assets/logo.png";
import { NavLink } from "react-router-dom";
import "./NavMenu.css";
//material icons
import PaymentIcon from '@mui/icons-material/Payment';
import AppsIcon from '@mui/icons-material/Apps';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useTranslation } from 'react-i18next'


const NavMenu = props => {
  const {t} = useTranslation()
  return (
    <>
      <div className="page-logo justify-content-center">
        <a href="/dashboard">
            {/* <img src="img/Icons/SVG/Trii.svg" style={{width:48, height:48}} /> */}
        </a>      
      </div>

      <ul className="nav flex-column">
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>Dashboard</Tooltip>}>
            <NavLink  to={"/dashboard"} className="nav-link">
                <div className="fal fa-tachometer-alt-fast fa-1-6x" aria-hidden="true">                     
                </div>
            </NavLink>
          </OverlayTrigger>
        </li>
      
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>{t("global.userAccounts")}</Tooltip>}>
            <NavLink  to="/users"  className="nav-link" data-toggle="tooltip" data-placement="right">
                <AppsIcon></AppsIcon>
            </NavLink>
            </OverlayTrigger>
        </li>
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>{t("global.wspAccounts")}</Tooltip>}>
            <NavLink  to="/wa_accounts"  className="nav-link" data-toggle="tooltip" data-placement="right">
                <WhatsAppIcon></WhatsAppIcon>
            </NavLink>
            </OverlayTrigger>
        </li>
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>{t("global.payments")}</Tooltip>}>
            <NavLink  to="/billing"  className="nav-link" data-toggle="tooltip" data-placement="right">
                <PaymentIcon></PaymentIcon>
            </NavLink>
        </OverlayTrigger>
        </li>
        {/* <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip>FAQ</Tooltip>}>
            <NavLink  to="/faq"  className="nav-link" data-toggle="tooltip" data-placement="right">
                <HelpOutlineIcon></HelpOutlineIcon>
            </NavLink>
        </OverlayTrigger>
        </li> */}
      
        {/* <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip>Modules</Tooltip>}>
            <NavLink exact activeClassName="activeTwo" to="/modules" className="nav-link" data-toggle="tooltip" data-placement="right">
                <span className="fal fa-cubes fa-1-6x" aria-hidden="true"></span>
            </NavLink>
            </OverlayTrigger>
        </li> */}
        {/* <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
        <OverlayTrigger placement="right" overlay={<Tooltip>API</Tooltip>}>
          <NavLink  to="/docs" className="nav-link" data-toggle="tooltip" data-placement="right">             
              <ApiIcon></ApiIcon>
          </NavLink>
        </OverlayTrigger>

        </li> */}
      </ul>
    </>
  );
};

export default NavMenu;