import React from "react";
import NavMenu from "./NavMenu";
import EnlacesLogin from "./EnlacesLogin";
import "./MainLayout.css";

function MainLayout(props) {
    //console.log(props)
    return (
      <div className="page-wrapper">
        <div className="page-inner">
          <div className="page-sidebar sidebar-sticky">
            <NavMenu />
          </div>
          <div
            className="page-content-wrapper"
            style={{ backgroundColor: "#efefef" }}
          >
            <div className="page-header header-sticky">
              <EnlacesLogin authenticated={true} />
            </div>
            <div id="js-page-content" className="page-content">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    );
}

export default MainLayout;