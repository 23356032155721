import React, {useState,useContext, useEffect} from 'react' // eslint-disable-line
// import { useTranslation } from 'react-i18next'
// import {DataContext} from '../Context/contextApi'
// import { getDocs, collection } from "firebase/firestore";
// import BoxInfoState from '../components/utils/boxInfoState'
// import LayoutCentered from '../components/layoutCentered'
import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/Add';
// import { NavLink } from "react-router-dom";

// import {db} from '../firebase'
// import "react-toastify/dist/ReactToastify.css";

import Spinner from './Spinner';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { APICuentas } from '../functions/ApiClient';
import { WaAccount } from '../Models/WaAccount';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// toast.configure()

const WaAccounts = () => {

  const columns = [
    { field: "uid", headerName: "uid", width: 0, hide: true },
    { field: "id", headerName: "id", width: 300 },
    { field: "Name", headerName: "Nombre", width: 300 },
    { field: "Status", headerName: "Estado", width: 300 },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          let uidRedirect = params.row.uid;
          console.log("params", uidRedirect);
          window.location.href = `/users/${uidRedirect}`;
        };

        return (
          <IconButton onClick={onClick}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const[users, setUsers] = useState([])
  let [chargingSpinner, setChargingSpinner] = useState(false)
  const [finalClickInfo, setFinalClickInfo] = useState(null);


  // const handleClickUserCell = (params) => {
  //   if (params) {
  //     // console.log("id", params);
  //     setFinalClickInfo(params);
  //   } else {
  //     return;
  //   }
  // };
  // useEffect(async() => {
  //   if (users !== null){
  //     // console.log("listaUsuarios", users)
  //   }
  //   if(finalClickInfo !== null && users !== null){
  //     // console.log("userid", finalClickInfo.uid);

  //   }
  // }, [users])
  const fetchData = async () => {
    try {
      // const res = await fetch('https://jsonplaceholder.typicode.com/users')
      // const data = await res.json()
      // setUsers(data)
      setChargingSpinner(true);

      let result = await APICuentas.getCuentas();
      let array = [];
      result.data.forEach((json) => {
        let jsonWa = new WaAccount(json);
        array.push(jsonWa);
      });

      setUsers(array);
      setChargingSpinner(false);
    } catch (error) {}
  }; 
  useEffect(() => {
    fetchData()
  },[])
  const rowData = users?.map((user) => {
    return {
      uid: user?.uid,
      id: user?.id,
      Name: user?.name, 
      Status: user?.wa_status
    };
  });
  // console.log(rowData)

  return (
    <>
     {chargingSpinner === false ? (
        <div style={{ height: 700, width: "100%", backgroundColor: "white" }}>
          <DataGrid
            rows={rowData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            // onCellClick={handleClickUserCell}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      ) : (
        <Spinner style={{ top: "35vh" }}></Spinner>
      )}
      {/* <div className="content">
        <h2 className="text-center m-3 h1">{t("global.myAccounts")}</h2>
      </div>
      <LayoutCentered>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
        </div>
        {collectionAccounts.map((item, i) => (
          <BoxInfoState key={i} account={item} />
        ))}
      </LayoutCentered> */}
    </>
  );
};

export default WaAccounts;
