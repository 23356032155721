import React, {Suspense} from "react";
import ReactDOM from "react-dom";
//import { Auth0Provider } from '@auth0/auth0-react';
// import Router from "./router";
import App from "./App";


// import { BrowserRouter as Router } from "react-router-dom";
import './i18n' //Traduccion

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";


// ReactDOM.render(
//   <Auth0Provider
//     domain="trii.us.auth0.com"
//     clientId="06GFaPxB0JuCOQupPSC9dRXPbjpJkLej"
//     redirectUri={window.location.origin}
//     audience='https://api.trii.app'
//   >
//     <Provider store={store}>
//       <Router />
//     </Provider>
//   </Auth0Provider>
//   ,
//   document.getElementById("root") 
// );



ReactDOM.render(
  //<React.StrictMode>
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        {/* <Router> */}
          {/* <Auth0ProviderWithHistory> */}
          <App />
          {/* </Auth0ProviderWithHistory> */}
        {/* </Router> */}
      </I18nextProvider>
    </Suspense>,
    

  //</React.StrictMode> 
  //se agrego React.StrictMode y suspense pero falta averiguar el funcionamiento
  document.getElementById("root")
);
