import React, { useContext } from "react";
// import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "../utils/boxInfoState.css";
import Button from "@mui/material/Button";
// import EditIcon from "@mui/icons-material/Edit";
import { DataContext } from "../../Context/contextApi";
import PropTypes from 'prop-types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import Stack from "@mui/material/Stack";



const BoxInfoState = (props) => {
  const { t } = useTranslation();
  const { setEditAccount } = useContext(DataContext);
  // let props.account = props.account


  return (
    <div
      className="panel"
      style={{
        background: "transparent",
        border: 0,
        boxShadow: "unset",
        marginTop: 25,
      }}
    >
      <div className="row">
        <div className="col-lg-12 col-md-12">
          {/* pendiente de pago */}
          <div
            className="panel-inner"
            style={
              props.account.wa_status === "UNPAIRED_IDLE" ||
              props.account.wa_status === "UNPAIRED"
                ? { borderLeft: "5px solid #f44336" }
                : props.account.wa_status === "CONNECTED"
                ? { borderLeft: "5px solid #66bb6a" }
                : { borderLeft: "5px solid #ffa726" }
            }
          >
            <div className="panel-container">
              <div className="panel-content">
                <div className="d-flex justify-content-between align-items-center">
                  {/* boton pendiente y saldo */}
                  <div
                    className="m-0"
                    style={{ width: "100%", maxWidth: "95%" }}
                  >
                    {props.account.wa_status === "UNPAIRED_IDLE" ||
                    props.account.wa_status === "UNPAIRED" ? (
                      <div className="waStatusUnpaired">
                        <div className="ml-1">
                          {/* <i className="fal fa-exclamation-triangle"></i> */}
                          <WarningAmberIcon fontSize="medium"></WarningAmberIcon>
                        </div>
                        {/* <div className="lineVertical"></div> */}
                        <span className="spanButton">
                          {props.account.wa_status}
                        </span>
                      </div>
                    ) : props.account.wa_status === "CONNECTED" ? (
                      <div className="waStatusConnected">
                        <div className="ml-1">
                          {/* <i className="fal fa-exclamation-triangle"></i> */}
                          <CheckIcon fontSize="medium"></CheckIcon>
                        </div>
                        {/* <div className="lineVertical"></div> */}
                        <span className="spanButton">
                          {props.account.wa_status}
                        </span>
                      </div>
                    ) : (
                      <div className="waStatusWarning">
                        <div className="ml-1">
                          {/* <i className="fal fa-exclamation-triangle"></i> */}
                          <WarningAmberIcon fontSize="medium"></WarningAmberIcon>
                        </div>
                        {/* <div className="lineVertical"></div> */}
                        <span className="spanButton">
                          {props.account.wa_status}
                        </span>
                      </div>
                    )}

                    <div className="stateName">{props.account.name}</div>
                  </div>

                  <form className="form-group">
                    <div style={{ width: "100%", margin: "10px 0px" }}>
                      <Stack spacing={4} direction="column">
                        <Button
                          variant="contained"
                          size={"small"}
                          className="buttonsActionsWa"
                          // disabled={}
                        >
                          Reiniciar
                        </Button>
                        <Button
                          variant="contained"
                          size={"small"}
                          className="buttonsActionsWa"
                        >
                          Desvincular
                        </Button>
                      </Stack>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BoxInfoState.propTypes = {
  account: PropTypes.any,
};
export default BoxInfoState;
