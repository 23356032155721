import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyA1NSegccl5gB4OtqtCDrxPDKjYw6l-ReA",
  authDomain: "api-qr-admin.firebaseapp.com",
  projectId: "api-qr-admin",
  storageBucket: "api-qr-admin.appspot.com",
  messagingSenderId: "291916082437",
  appId: "1:291916082437:web:5ed2e2afb7d23c6c859b6c"
  };

// require('firebase/analytics')
// require('firebase/storage')

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const analytics = firebase.analytics();
// const storage = firebase.storage();
//console.log('analytics',analytics)
//console.log('storage',storage)

const app = initializeApp(firebaseConfig);
const auth = firebase.auth()
const db = getFirestore();

// const provider = new firebase.auth.GoogleAuthProvider()

// export {auth}

export {app, db, auth};