import React from "react";
import Router from "./router";
import DataProvider  from "./Context/contextApi";
import { BrowserRouter as BrowseRouter } from "react-router-dom";
import "./App.css"

// import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const App = () => {
 
  return (
    <>
      {
        <DataProvider>
          <BrowseRouter>
            <Router />
          </BrowseRouter>
        </DataProvider>
      }
    </>
  );
}



export default App;
// export default App;