/* eslint-disable react/no-unescaped-entities */

import React, { Fragment, useState, useContext, useCallback, useEffect } from "react"; //eslint-disable-line
import { NavLink } from "react-router-dom";
import "./EnlacesLogin.css";
import { useTranslation } from "react-i18next";
import ModalEditUser from "./ModalEditUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataContext } from "../Context/contextApi";
import PropTypes from 'prop-types';

toast.configure();

const EnlacesLogin = ({ authenticated }) => {
  const { cerrarSesion, auth } = useContext(DataContext);

  //Traduccion
  const { t, i18n } = useTranslation(); //eslint-disable-line
  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };

  let select = JSON.parse(localStorage.getItem("select"));

  if (!select) {
    select = "";
  }
  const [value, setValue] = useState(select); //eslint-disable-line

  // const handleChangeLenguaje = useCallback((e) => { 

  //   if (e.target.value === "espanol") {
  //     changeLanguage("Es");
  //     setValue("espanol");
  //   } else {
  //     setValue("ingles");
  //     changeLanguage("En");
  //   }
  //   localStorage.setItem("select", JSON.stringify(e.target.value));
  // },[value]); //eslint-disable-line

  const [modalEditar, setModalEditar] = useState(false);
  const [user, setUser] = useState({
    id: "19",
    name: "New user",
    email: "userTest@gmail.com",
    phone: "3584654987",
  });

  // const editarUsuario = () => {
  //   setModalEditar(true);
  // };

  const onHandleSave = (usuario) => {
    setUser(usuario);
    toast.success(`usuario ${usuario.name} editado`);
    setModalEditar(false);
  };

  const onHandleClose = () => {
    setModalEditar(false);
  };
  // Prevents menu from closing when clicked inside
//   useEffect(() => {
//    var dropdownProfile = document.querySelector(".dropdown-menu");
//    var dropdownSelectProfile = document.querySelector(".selectDropProfile");
//    if (dropdownProfile == null) {
//      return;
//    } else {
//     dropdownSelectProfile.addEventListener("click", function(event) {
//       // alert("click outside");
//       event.stopPropagation();
//     });
//      window.onclick = function(event) {
//        if (!event.target.matches(".header-icon")) {
//          var dropdowns = document.getElementsByClassName("dropdown-menu");
         
//          var i;
//          for (i = 0; i < dropdowns.length; i++) {
//            var openDropdown = dropdowns[i];
//            if (openDropdown.classList.contains("show")) {
//              openDropdown.classList.remove("show");
//              event.stopropagation();
//            }
//          }
//        }
//      };
//    }


//  }, []);


  // Closes the menu in the event of outside click



  return (
    <Fragment>
      {authenticated ? (
        <>
          {/*DROPDOWN App*/}
          <span className="h4 m-0">api-qr</span>
          <div className="navbar ml-auto">
            {/*Seccion USUARIO*/}
            <div>
              <div
                data-toggle="dropdown"
                // title={userProfile.displayName}
                className="header-icon"
                aria-expanded="false"
              >
               {auth.currentUser.email}
              </div>

              <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                  <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                    <span className="mr-2">                   
                    </span>
                    <div className="info-card-text">
                      
                        <span className="text-truncate text-truncate-md opacity-80">
                          {auth.currentUser.email}
                        </span>
                    
                    </div>
                  </div>
                </div>
                {/* <a
                  className="dropdown-item fw-500 py-3"
                  // onClick={editarUsuario}
                  href="/profile"
                  to="/profile"
                >
                  {t("global.profile")}
                </a> */}
                <div className="dropdown-divider m-0"></div>
                {/* <div
                  id="idiomaEstilo"
                  className="dropdown-lang py-3 px-4 hover-white"
                >
                  <strong className="fw-500">{t("global.languages")}</strong>
                  <select
                    className="custom-select adjust-select mt-2 selectDropProfile"
                    value={value}
                    onChange={handleChangeLenguaje}
                  >
                    <option className="select2-results__option" value="espanol">
                      {t("global.spanish")}
                    </option>
                    <option className="select2-results__option" value="ingles">
                      {t("global.english")}
                    </option>
                  </select>
                </div> */}
                {/*<SelectorIdiomas />*/}
                <div className="dropdown-divider m-0"></div>

                <div
                  className="dropdown-item fw-500 py-3"
                  // href="/signin"
                  onClick={cerrarSesion}
                >
                  {t("global.logout")}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <NavLink to="auth">
            <button color="inherit">{t("global.login")}</button>
          </NavLink>
        </div>
      )}

      {modalEditar ? (
        <ModalEditUser
          user={user}
          setUser={setUser}
          onHandleSave={onHandleSave}
          onHandleClose={onHandleClose}
        />
      ) : null}
    </Fragment>
  );
};;
EnlacesLogin.propTypes = {
  authenticated: PropTypes.bool,
};
export default EnlacesLogin;
