import React, { useState, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Input from "@material-ui/core/Input";
import "./Profile.css";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { DataContext } from "../Context/contextApi";
import { Spinner } from "react-bootstrap";

import { doc } from "firebase/firestore";
import { db } from "../firebase";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { APIUserAccount } from "../functions/ApiClient";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


toast.configure();

const Profile = () => {
  const { userProfile, setUserProfile, detectarDocumentoUsuario } = useContext(DataContext);

  const auth = getAuth();
  // let userAccountApi = new userAccount();
  // let imgProfile = userAccountApi.imgProfile
  // let providerPass = auth.currentUser.providerData[0].providerId
  let providerPass = userProfile.providerPass;
  const resetPassword = () => {
    try {
      
        providerPass === "password" &&
          sendPasswordResetEmail(auth, auth.currentUser.email)
            .then(() => {
              // Password reset email sent!
              // alert(`${t("profile.passwordResetEmailSent")}`);
              toast.success(t("profile.passwordResetEmailSent"));
            })
            .catch((error) => {
              const errorCode = error.code; // eslint-disable-line
              const errorMessage = error.message;
              toast.error(errorMessage);

              // ..
            });
      
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();
  let profile = JSON.parse(localStorage.getItem("profile"));
  profile = {
    id: "1",
    logo:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=",
    nombre: "Lautaro",
    telefono: "1234567",
    domicilio: "domicilioGral",
    email: "mail@mail.com",
    cuit: "12345567888",
  };
  const [errorSubmit, setErrorSubmit] = useState(false); // eslint-disable-line
  const [empresa, setEmpresa] = useState(profile); // eslint-disable-line
  const [statusOk, setStatusOk] = useState(true);
  const [statusFacturaOk, setStatusFacturaOk] = useState(true);
  // const [showModal, setShowModal] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [openPopupDeleteAcc, setOpenPopupDeleteAcc] = useState(false);


  const { t } = useTranslation();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setEmpresa({
  //     ...empresa,
  //     [name]: value,
  //   });
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const changeNameProfile = async () => {
    setStatusOk(false);
    let uNameProfile = document.querySelector("#inputUsernameProfile").value;
    if (uNameProfile === "") {
      setErrorSubmit(true);
      toast.error(t("tostify.nameNull"));
      return;
    }
    if (uNameProfile.length <= 2) {
      toast.error(t("tostify.lessTwo"));
      setErrorSubmit(true);
      return;
    }
    if (uNameProfile.length > 100) {
      toast.error(t("tostify.overHundred"));
      setErrorSubmit(true);
      return;
    } else {
      // const DocRef = doc(db, "users", userProfile.uid);
      // await updateDoc(DocRef, {
      //   name: uNameProfile,
      // });
      try {
        let result = await APIUserAccount.actualizarNombrePerfil(
          userProfile.uid,
          uNameProfile
        );
        result.status === 200 && toast.success(t("tostify.changesSaved"));
        setStatusOk(true);
        detectarDocumentoUsuario();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const saveBillData = async () => {
    setStatusFacturaOk(false);
    let billName = document.querySelector("#inputBillName").value;
    let billDirection = document.querySelector("#inputBillDirection").value;
    let billCuit = document.querySelector("#inputBillCuit").value;

    try {
      let result = await APIUserAccount.actualizarFacturaPerfil(
        userProfile.uid,
        billName,
        billDirection,
        billCuit
      );
      // const DocRef = doc(db, "users", userProfile.uid);
      // await updateDoc(DocRef, {
      //   billData: {Name: billName, Direction: billDirection, Cuit: billCuit}
      // });
      
        result.status === 200 && toast.success(t("tostify.changesSaved"));
      
      setStatusFacturaOk(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfileImg = useCallback(
    async (e) => {
      e.preventDefault();
      let fileUp = e.target.files[0];
      let file = fileUp.name;
      if (file.size > 10485760) {
        return alert("imagen pesada");
      }

      //storage
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // console.log(reader)
      const storage = getStorage();
      const imgProfileRef = ref(storage, `${userProfile.uid}/images/Image.png`);

      uploadBytes(imgProfileRef, fileUp).then(async (snapshot) => {
        console.log("Uploaded a blob or file!");
        console.log(snapshot);
        getDownloadURL(
          ref(storage, `${userProfile.uid}/images/Image.png`)
        ).then(async (url) => {
          console.log("imageUrl", url);
          const DocRef = doc(db, "users", userProfile.uid);
          console.log("DocRef", DocRef);
          console.log("user", userProfile.uid);

          // updateDoc(DocRef, {
          //   imgProfile: url,
          // });
          setUserProfile({ ...userProfile, imgProfile: url });
          
          // eslint-disable-next-line
          let result = await APIUserAccount.actualizarImagenPerfil(
            userProfile.uid,
            url
          );
          // console.log('11111111111', result)
        });
      });
      console.log(auth);
      console.log(userProfile);

      // console.log(storage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, userProfile]
  );

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (errorSubmit === false) {
  //     toast.success(t("tostify.changesSaved"));
  //   }
  // };

  const Input = styled("input")({
    display: "none",
  });

  const openDeleteDialog = () => {
    setOpenPopupDeleteAcc(true);
  };
  const deleteAccount = async () => {
    setOpenPopupDeleteAcc(false);
    // await deleteDoc(
    //   doc(db, "users", userProfile.uid, "accounts", editAccount.id)
    // );

    let result =await  APIUserAccount.eliminarCuentaPerfil(userProfile.uid)  // eslint-disable-line
    //console.log('delete account: ', resultado)


    toast.warning(t("tostify.deletedAccount"));
  };

  return (
    <>
      {userProfile == null && <Spinner>Cargando</Spinner>}
      <div className={classes.root}>
        <div className="border-faded border-0">
          <div className="page-content">
            <div className="content">
              <div className="text-center m-1 h1"></div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div id="panel-1" className="panel">
                  <div className="panel-hdr">
                    <h2 className="h2 ">{t("profile.personalInformation")}</h2>
                  </div>
                  <div className="panel-container show">
                    <div className="panel-content">
                      <form className="form-group">
                        <Grid item container xs={12}>
                          <Box
                            display="flex"
                            flexDirection={{ xs: "column", sm: "row" }}
                            alignItems={{ xs: "stretched", sm: "center" }}
                            justifyContent={"flex-"}
                            width={1}
                            margin={"0 auto"}
                          >
                            <label
                              htmlFor="idProfileImgInput"
                              title={t("global.updatePhoto")}
                              className={"profilePhoto"}
                            >
                              <Input
                                id="idProfileImgInput"
                                type="file"
                                onChange={handleProfileImg}
                              />
                              {userProfile.imgProfile === null ||
                              userProfile.imgProfile === "" ? (
                                <img
                                  alt=""
                                  src="img/demo/avatars/avatar-m.png"
                                  className={
                                    isShown
                                      ? "editIconProfile rounded-circle"
                                      : "rounded-circle"
                                  }
                                  style={{
                                    width: "10rem",
                                    height: "10rem",
                                    cursor: "pointer",
                                  }}
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}                                  
                                />
                              ) : (
                                <img
                                  src={userProfile.imgProfile}
                                  className={
                                    isShown
                                      ? "editIconProfile rounded-circle"
                                      : "rounded-circle"
                                  }
                                  style={{
                                    width: "10rem",
                                    height: "10rem",
                                    cursor: "pointer",
                                  }}
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                  alt=""
                                />
                              )}

                              {isShown && (
                                <EditIcon
                                  onMouseEnter={() => setIsShown(true)}
                                  style={{
                                    position: "absolute",
                                    left: "3.3em",
                                    width: "35px",
                                    height: "35px",
                                    top: "3.3em",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                ></EditIcon>
                              )}
                            </label>
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "column",
                                sm: "column",
                                md: "column",
                              }}
                              alignItems={{
                                xs: "stretched",
                                sm: "center",
                                md: "start",
                              }}
                              justifyContent={"flex-start"}
                              className={"marginLeftImgProfile"}
                              width={1}
                              margin={"0 auto"}
                            >
                              <span className={"profileEmailSpan"}>
                                {userProfile.email}
                              </span>
                              <span className={"profileEmailProvider"}>
                                {t("profile.provider")}:{" "}
                                {userProfile.providerPass}
                              </span>
                              <TextField
                                fullWidth
                                id="inputUsernameProfile"
                                label={t("global.name")}
                                name="nombre"
                                variant="outlined"
                                defaultValue={userProfile.name}
                                className="mb-4 mt-5"
                              />

                              <Grid item container xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection={{ xs: "column", sm: "row" }}
                                  alignItems={{ xs: "stretched", sm: "center" }}
                                  justifyContent={"flex-end"}
                                  width={1}
                                  margin={"0 auto"}
                                  // marginTop={2}
                                >
                                  <Button
                                    size={"small"}
                                    variant="contained"
                                    onClick={changeNameProfile}
                                    disabled={statusOk === false ? true : false}
                                    startIcon={
                                      statusOk === false ? (
                                        <Spinner
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            fontSize: "12px",
                                          }}
                                          animation="border"
                                        />
                                      ) : null
                                    }
                                  >
                                    {statusOk === false
                                      ? t("global.saving")
                                      : t("global.save")}
                                  </Button>
                                </Box>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </form>
                    </div>
                  </div>
                </div>
                <div id="panel-2" className="panel">
                  <div className="panel-hdr">
                    <h2 className="h2 ">{t("profile.accountId")}</h2>
                  </div>
                  <div className="panel-container show">
                    <div className="panel-content">
                      <form className="form-group">
                        <TextField
                          fullWidth
                          disabled
                          id="AccountID"
                          label={t("profile.accountId")}
                          defaultValue={userProfile.uid}
                          variant="outlined"
                          name="AccountID"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div id="panel-3" className="panel">
                  <div className="panel-hdr">
                    <h2 className="h2 ">{t("global.password")}</h2>
                  </div>
                  <div className="panel-container show">
                    <div className="panel-content">
                      <Grid item container xs={12}>
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          alignItems={{ xs: "stretched", sm: "center" }}
                          justifyContent={"flex-start"}
                          width={1}
                        >
                          <Link
                            component={"button"}
                            color={"primary"}
                            onClick={resetPassword}
                            underline={"none"}
                            className={
                              providerPass === "password" ? "" : "disabledLink"
                            }
                          >
                            <Button
                              variant="outlined"
                              component={"a"}
                              disabled={
                                providerPass !== "password" ? true : false
                              }
                            >
                              <Typography
                                variant={"subtitle2"}
                                className={
                                  providerPass === "password"
                                    ? ""
                                    : "disabledLink"
                                }
                              >
                                {t("profile.restorePassword")}
                              </Typography>
                            </Button>
                          </Link>
                        </Box>
                      </Grid>
                    </div>
                  </div>
                </div>
                <div id="panel-4" className="panel">
                  <div className="panel-hdr">
                    <h2 className="h2 ">{t("billing.billingInformation")}</h2>
                  </div>
                  <div className="panel-container show">
                    <div className="panel-content">
                      <form>
                        <TextField
                          fullWidth
                          id="inputBillName"
                          label={t("global.name")}
                          name="nombre"
                          variant="outlined"
                          defaultValue={userProfile.billing_name}
                          className="mb-4"
                        />
                        <TextField
                          fullWidth
                          id="inputBillDirection"
                          label={t("global.direction")}
                          name="direccion"
                          variant="outlined"
                          defaultValue={userProfile.billing_direction}
                          className="mb-4"
                        />
                        <TextField
                          fullWidth
                          id="inputBillCuit"
                          label="Cuit"
                          name="cuit"
                          variant="outlined"
                          defaultValue={userProfile.billing_cuit === 0 ? "" : userProfile.billing_cuit}
                          className="mb-4"
                        />
                      </form>
                      <Grid item container xs={12}>
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          alignItems={{ xs: "stretched", sm: "center" }}
                          justifyContent={"flex-end"}
                          width={1}
                          margin={"0 auto"}
                          // marginTop={2}
                        >
                          <Button
                            size={"small"}
                            variant="contained"
                            type="button"
                            onClick={saveBillData}
                            disabled={statusFacturaOk === false ? true : false}
                            startIcon={
                              statusFacturaOk === false ? (
                                <Spinner
                                  style={{
                                    height: "1.5rem",
                                    width: "1.5rem",
                                    fontSize: "12px",
                                  }}
                                  animation="border"
                                />
                              ) : null
                            }
                          >
                            {statusFacturaOk === false
                              ? t("global.saving")
                              : t("global.save")}
                          </Button>
                        </Box>
                      </Grid>
                    </div>
                  </div>
                </div>
                <div id="panel-5" className="panel">
                  <div className="panel-hdr">
                    <h2 className="h2 ">{t('global.deleteAccount')}</h2>
                  </div>
                  <div className="panel-container show">
                    <div className="panel-content">
                      <form className="form-group">
                        <div style={{ textAlign: "end" }}>
                          <Button
                            size={"small"}
                            variant="contained"
                            type="button"
                            color="error"
                            startIcon={<DeleteIcon />}
                            // disabled={statusOk == false ? true: false}
                            // startIcon={statusOk === false? <Spinner style={{height: '1.5rem', width: '1.5rem', fontSize: '12px'}} animation="border"/> : null}
                            onClick={openDeleteDialog}
                          >
                            {t('global.deleteAccount')}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openPopupDeleteAcc}
        onClose={() => setOpenPopupDeleteAcc(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deletePrincipalAccount.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t("deletePrincipalAccount.subtitle")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopupDeleteAcc(false)}>
            {t("global.cancel")}
          </Button>
          <Button onClick={deleteAccount} color="error">
            {t("global.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
