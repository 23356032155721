import React, {useState,useContext, useEffect, useCallback} from 'react' // eslint-disable-line
// import { useTranslation } from 'react-i18next'
// import {DataContext} from '../Context/contextApi'
// import { getDocs, collection } from "firebase/firestore";
// import BoxInfoState from '../components/utils/boxInfoState'
// import LayoutCentered from '../components/layoutCentered'
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
// import { NavLink } from "react-router-dom";

// import {db} from '../firebase'
// import "react-toastify/dist/ReactToastify.css";

import Spinner from '../Spinner';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { APIUserAccount } from '../../functions/ApiClient';
import { UserAccount } from '../../Models/UserAccount';
import { DataContext } from '../../Context/contextApi';
// toast.configure()

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';


const Accounts = () => {


  const columns = [
    { field: 'id', headerName: 'id', width: 300},
    { field: 'Name', headerName: 'Nombre', width: 300 },
    { field: 'Email', headerName: 'Email', width: 300 },
    { field: 'CantidadCuentas', headerName: 'Cantidad de cuentas', width: 300, type: 'number' },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          let idRedirect = params.row.id;
          window.location.href = `/users/${idRedirect}`;
        };

        return (
          <IconButton onClick={onClick}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const[users, setUsers] = useState([])
  let [chargingSpinner, setChargingSpinner] = useState(false)
  const [finalClickInfo, setFinalClickInfo] = useState(null);
  const {userProfile} = useContext(DataContext);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const handleClickUserCell = (params, event, details) => {
  //   if (params) {
  //     console.log("id", params.id);
  //     console.log("event", event.target);
  //     setFinalClickInfo(params);
  //   } else {
  //     return;
  //   }
  // };
  // useEffect(async() => {
  //   if (users){
  //     console.log("listaUsuarios", users)
  //   }
  //   if(finalClickInfo !== null){
  //     window.location.href = `/users/${finalClickInfo.id}`


  //     console.log("userid", finalClickInfo.id);
  //   }
  // }, [finalClickInfo])

  const fetchData = async () => {
    try {
      // const res = await fetch('https://jsonplaceholder.typicode.com/users')
      // const data = await res.json()
      // setUsers(data)
      setChargingSpinner(true);

      let result = await APIUserAccount.obtenerListaUsuarios();
      let array = [];
      result.data.forEach((json) => {
        let jsonWa = new UserAccount(json);
        array.push(jsonWa);
      });
      

      setUsers(array);  
      
      setChargingSpinner(false);
    } catch (error) {}
  }; 
  useEffect(() => {
    fetchData()
  },[])
  const rowData = users?.map((user) => {
    return {
      id: user?.uid,
      Name: user?.name,
      Email: user?.email,
      CantidadCuentas: user?.TotalAccounts
    };
  });

  return (
    <>
      {chargingSpinner === false ? (
        <div style={{ height: 700, width: "100%", backgroundColor: "white" }}>
          <DataGrid
            rows={rowData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            // onCellClick={handleClickUserCell}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      ) : (
        <Spinner style={{ top: "35vh" }}></Spinner>
      )}
      {/* <div className="content">
        <h2 className="text-center m-3 h1">{t("global.myAccounts")}</h2>
      </div>
      <LayoutCentered>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
        </div>
        {collectionAccounts.map((item, i) => (
          <BoxInfoState key={i} account={item} />
        ))}
      </LayoutCentered> */}
    </>
  );
};

export default Accounts;
